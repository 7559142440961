/* pt-sans-latin-400-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./assets/fonts/pt-sans-latin-400-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-latin-400-normal.woff) format('woff');
}
/* pt-sans-latin-ext-400-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./assets/fonts/pt-sans-latin-ext-400-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-latin-ext-400-normal.woff) format('woff');
}
/* pt-sans-latin-700-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/fonts/pt-sans-latin-700-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-latin-700-normal.woff) format('woff');
}
/* pt-sans-latin-ext-700-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/fonts/pt-sans-latin-ext-700-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-latin-ext-700-normal.woff) format('woff');
}
/* pt-sans-cyrillic-400-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./assets/fonts/pt-sans-cyrillic-400-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-cyrillic-400-normal.woff) format('woff');
}
/* pt-sans-cyrillic-ext-400-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./assets/fonts/pt-sans-cyrillic-ext-400-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-cyrillic-ext-400-normal.woff) format('woff');
}
/* pt-sans-cyrillic-700-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/fonts/pt-sans-cyrillic-700-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-cyrillic-700-normal.woff) format('woff');
}
/* pt-sans-cyrillic-ext-700-normal */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/fonts/pt-sans-cyrillic-ext-700-normal.woff2) format('woff2'),
    url(./assets/fonts/pt-sans-cyrillic-ext-700-normal.woff) format('woff');
}

body {
  margin: 0rem 0rem 5rem 0rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PT Sans', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;

  background-color: #f6f7f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}
