.App {
  text-align: center;
  height: 100vh;
  margin-top: 10vh;
}

.App-logo {
  height: 6vh;
  pointer-events: none;
}

.App-logo.nav-logo {
  height: 8vh;
}

.App-logo.Loading {
  height: 15vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: bounce 1s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-iteration-count: infinite;
  }
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 50px, 0);
  }
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 50px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

@media (max-width: 550px) {
  .hide-on-mobile {
    display: none;
  }
}

@media (max-width: 950px) {
  .hide-on-small {
    display:none
  }
}